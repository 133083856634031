.mainHeader {
    background-color: var(--mantine-color-gray-1);
    // position: relative;
    z-index: 1000;
}

.skipLink {
    display: block;
    padding: 16px 24px;
    border-radius: 4px;
    position: fixed;
    top: 4px;
    left: 4px;
    background-color: #fff;
    color: black;
    text-decoration: none;
    transform: translate(-150%);
    border: 2px solid rgba(0, 0, 0, 0);

    &:focus {
        transform: translate(0);
        border: 2px solid #000;
    }
}

.logo {
    display: block;
}

.logoImg {
    display: block;
    width: 220px;
    padding-top: 12px;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.mainMenu {
    justify-content: flex-end;
}

.navItem {
    position: relative;
    border: 1px solid transparent;
}

.navLink {
    display: flex;
    align-items: center;
    padding: 8px;
    color: black;
    text-decoration: none;
    border-radius: 4px;

    &[aria-expanded="true"] {
        border: 1px solid var(--mantine-color-gray-2);
    }

    svg {
        width: 16px;
        height: 16px;
        margin: 2px 0 0 6px;
    }

    &:hover {
        color: var(--mantine-color-violet-7);
        background-color: #fff;
    }

    &.navLinkCurrent {
        position: relative;
        color: var(--mantine-color-violet-7);

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 1px;
            border-top: 2px solid var(--mantine-color-violet-7);
            position: absolute;
            bottom: 8px;
            left: 8px;
        }
        
    }
}

.navSubLink {
    text-decoration: none;
    color: #000;
    display: flex;
    align-items: center;
    padding: 2px 8px;
    border-radius: 4px;
}

@media (max-width: 1023px) {
    .mainHeader {
        padding: 6px 0;

        .logoImg {
            width: 150px;
            position: relative;
            z-index: 999;
        }
    }

    .mainMenu {
        display: none;
    }

    .mobileMenu {
        position: absolute;
        z-index: 1001;
        top: 59px;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 12px;
        background-color: var(--mantine-color-gray-1);
        display: none;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        flex-direction: column;
        align-items: stretch;

        &.mobileMenuOpen {
            display: flex;
        }
    }

    .mobileSubmenu {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid var(--mantine-color-gray-3);
        padding-bottom: 12px;

        .navLink {
            font-weight: bold;
        }

        .navSubLink {
            padding: 8px;
            margin-left: 12px;
        }
    }

    .menuToggle {
        position: absolute;
        appearance: none;
        top: 5px;
        right: 6px;
        width: 44px;
        height: 44px;
        padding: 0;
        margin: 0;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: {
            color: transparent;
            image: url('/assets/btn-menu.png');
            size: 44px 44px;
            position: center;
            repeat: no-repeat;
        }

        svg {
            width: 24px;
            height: 24px;
        }

        &:focus {
            color: var(--mantine-color-violet-6);
        }

        &.menuToggleOpen {
            background-image: url('/assets/btn-close.png');
        }
    }
}

@media (min-width: 1024px) {
    .mainHeader {
        padding: 24px 0;
    }

    .menuToggle {
        display: none;
    }

    .subMenu {
        margin-top: -10px;
        min-width: 200px;
    }

    .navLink {
        margin-right: 12px;
    }

    .mobileMenu {
        display: none;
    }
}

@media (pointer: fine) {
    .subMenu {
        &::before {
            content: '';
            display: block;
            position: absolute;
            cursor: pointer;
            top: -44px;
            left: 50%;
            height: 44px;
            width: 160px;
            margin-left: -80px;
            background-color: rgba(255, 255, 255, 0.21);
        }
    }
}