.mainFooter {
    padding: 48px 0;
    background-color: var(--mantine-color-gray-1);
}

.footerBrand {
    text-align: right;

    img {
        width: 180px;
    }
}

.footerMenu {
    list-style: none;
    margin: 0;
    padding: 6px 0;

    a {
        display: block;
        padding: 6px 0;
        text-decoration: none;
        color: var(--mantine-color-violet-6);

        &:hover {
            text-decoration: underline;
        }
    }
}

.footerCopyright {
    padding: 36px 0 12px 0;
    margin-top: 36px;
    border-top: 1px solid #dedef7;
}

.partnerLogo {
    display: block;
    width: auto;
    max-width: 160px;

    img {
        max-height: 72px;
        width: auto;
    }
}