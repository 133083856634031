body {
    margin: 0;
    padding: 0;
    font-family: var(--cf-font-dmsans), sans-serif;
    font-size: 16px;
    color: #291C33;
}

.section {
    padding: 48px 0;

    &.section-white {
        background-color: #fff;

        & + .section-white {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
        }

        .mantine-Accordion-control {
            background-color: var(--mantine-color-gray-0);
        }
    }

    &.section-light-gray {
        background-color: var(--mantine-color-gray-0);

        & + .section-light-gray {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
        }

        .single-card {
            background: linear-gradient(180deg, transparent 0%, transparent 30%, #fff 30%, #fff);
        }

        .single-card > div {
            background-color: #fff !important;
        }

        .mantine-Accordion-item {
            background-color: #fff !important;
        }

        .mantine-Paper-root {
            background: #fff !important;
        }
    }
    
    &.section-light-violet {
        background-color: var(--mantine-color-violet-0);

        & + .section-light-violet {
            border-top: 1px solid rgba(0, 0, 0, 0.125);
        }

        .single-card {
            background: linear-gradient(180deg, transparent 0%, transparent 30%, #fff 30%, #fff);
        }

        .single-card > div{
            background-color: #fff !important;
        }

        .mantine-Accordion-item {
            background-color: #fff !important;
        }
    }

    &.section-yellow {
        background-color: #f9da4a;
        border-bottom: 1px solid #fff;
    }

    &.section-violet {
        background-color: var(--mantine-color-violet-5);
        border-bottom: 1px solid #fff;
        color: #fff;

        .mantine-Button-root {
            background-color: #fff !important;
            border: 1px solid #fff !important;
            color: var(--mantine-color-violet-5) !important;

            &:hover {
                background-color: var(--mantine-color-violet-1) !important;
            }

            &[data-variant="outline"] {
                background-color: transparent !important;
                color: #fff !important;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.25) !important;
                }
            }
        }

        a:not(.mantine-Button-root) {
            color: #fff;
        }

        blockquote[class^="Testimonial_quote"] {
            background-image: url('/assets/quote-white.png');
        }

        .mantine-Timeline-itemBody {
            color: #fff;
        }

        .section-cta {
            .mantine-Button-root {
                display: block;
            }
        }
    }

    &.section-pink {
        background-color: #fc96d9;
        border-bottom: 1px solid #fff;

        .mantine-Button-root {
            background-color: #fff !important;
            border: 1px solid #fff !important;
            color: var(--mantine-color-violet-6) !important;

            &:hover {
                background-color: var(--mantine-color-gray-1) !important;
            }

            &[data-variant="outline"] {
                background-color: transparent !important;
                color: #fff !important;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.25) !important;
                }
            }
        }

        .section-cta {
            .mantine-Button-root {
                display: block;
            }
        }
    }
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}

.mantine-Badge-label {
    text-transform: none;
}

.feather {
    width: 16px;
    height: 16px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

a.icon-right .feather {
    width: 24px;
    height: 24px;
    margin-left: 12px;
}

a.icon-left .feather {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.mantine-Button-label {
    font-family: var(--cf-font-telegraf-med), sans-serif !important;
}

h1 {
    font-family: var(--cf-font-telegraf-md), sans-serif !important;
    font-weight: 500;
    margin-bottom: 0.25em;
}

h2,
h3 {
    font-family: var(--cf-font-telegraf-reg), sans-serif !important;
}

h2 {
    font-weight: var(--mantine-h2-font-weight);
    line-height: var(--mantine-h2-line-height);
    font-size: var(--mantine-h2-font-size);
    margin: 0;
}

h3 {
    font-weight: var(--mantine-h3-font-weight);
    line-height: var(--mantine-h3-line-height);
    font-size: var(--mantine-h3-font-size);
    margin-top: 0.25em;
    margin-bottom: 0.5em;
}

h4 {
    font-family: var(--cf-font-telegraf-med), sans-serif !important;
    margin: 0 0 0.25em 0;
}

p {
    margin: 0 0 1em 0;

    &:last-child {
        margin: 0;
    }
}

p.p-lead {
    font-size: 1.25em;
}

.feat-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 24px;
}

.content-area {
    .calloutbox {
        background-color: var(--mantine-color-violet-0);
        padding: 24px;
        border-left: 10px solid var(--mantine-color-violet-4);
        margin: 1em 0;
    }

    a:not(.mantine-Button-root) {
        color: var(--mantine-color-violet-6);
    }

    .testimonial {
        border-top: 1px solid var(--mantine-color-violet-4);
        border-bottom: 1px solid var(--mantine-color-violet-4);
        padding: 2em 0 2em 144px;
        margin: 2em 2em 2em 0;
        background: {
            image: url('/assets/quote.png');
            size: 112px auto;
            repeat: no-repeat;
            position: 0 2.25em;
        }

        .quote-text {
            font-size: 1.75em;
            line-height: 1.25;
            margin: 0 0 0.25em 0;
            font-family: var(--cf-font-telegraf-med);
        }

        .quote-author {
            font-size: 0.92em;
        }
    }

    blockquote:not(.testimonial) {
        border-left: 10px solid var(--mantine-color-violet-4);
        padding: 0.25em 1em;
        margin: 1em 0;
        font-size: 1.2em;
    }

    table {
        border: 1px solid var(--mantine-color-gray-3);
        border-collapse: collapse;
        width: 100%;
        margin: 0 0 1em 0;

        th,
        td {
            text-align: left;
            border: 1px solid var(--mantine-color-gray-3);
            padding: 8px;
        }
    }

    .video {
        position: relative;
        overflow: hidden;
        margin: 1em 0;

        .video-player {
            aspect-ratio: 16 / 9;

            iframe {
                display: block;
                margin: 0;
                width: 100%;
                height: 100%;
            }
        }

        .caption {
            font-size: 0.92em;
            font-weight: 500;
            padding: 12px;
            background-color: var(--mantine-color-gray-0);
        }
    }
}

// badges
.mantine-Badge-root {
    color: #291C33;
    background-color: #fff;
    border-color: var(--mantine-color-gray-3);
    padding-top: 5px;
    padding-bottom: 5px;
    height: 28px;

    .mantine-Badge-section {
        width: 12px;
    }
}

.mantine-Accordion-item {
    border: 1px solid var(--mantine-color-gray-3);
}
.mantine-Accordion-label {
    font-weight: 700 !important;
}
.mantine-Accordion-control {
    font-size: 1.125rem !important;
}
.mantine-Accordion-content {
    border-top: 2px solid var(--mantine-color-violet-4);
}
.mantine-Accordion-chevron {
    color: var(--mantine-color-violet-7);
}

.btn-arrow {
    border: 0;
    background: none;
    appearance: none;
    padding: 0;
    height: 44px;
    width: 44px;
    line-height: 56px;
    cursor: pointer;
    transition: all 0.25s ease-out;
    
    svg {
        width: 24px;
        height: 24px;
        transition: all 0.25s ease-out;
    }

    &:hover {
        svg {
            transform: translateX(4px);
            color: var(--mantine-color-violet-5);
        }
    }
}

.video-player {
    aspect-ratio: 16 / 9;
    width: 100%;

    iframe {
        display: block;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    & + .caption {
        background-color: var(--mantine-color-gray-0);
        padding: 6px 12px;
    }
}

@media (max-width: 719px) {
    h1 {
        font-size: 42px !important;
    }

    h2 {
        font-size: 32px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    .section {
        padding: 24px 0;
    }

    .content-area {
        .testimonial {
            padding: 64px 0 2em 0;
            margin: 1em 0;
            background-size: 60px auto;
            background-position: 0 12px;
        }
    }
}

@media (min-width: 720px) and (max-width: 1023px) {
    .cards-2 {
        flex-wrap: wrap;

        .single-card {
            flex: 0 1 48.3%;
        }
    }
    
    .cards-3 {
        flex-wrap: wrap;

        .single-card {
            flex: 0 1 31.1%;
        }
    }
    
    .cards-4 {
        flex-wrap: wrap;

        .single-card {
            flex: 0 1 48.3%;
        }
    }
}

@media (min-width: 1024px) {
    body {
        font-size: 18px;
    }

    h3 {
        font-size: 32px !important;
    }

    .cards-2 {
        .single-card {
            flex: 0 1 48.8%;
        }
    }
    
    .cards-3 {
        .single-card {
            flex: 0 1 31.8%;
        }
    }
    
    .cards-4 {
        .single-card {
            flex: 0 1 23.3%;
        }
    }
    
    .section-70-30 {
        .column-1 .content-area {
            padding-right: 12px;
            padding-left: 0;
        }
        .column-2 .content-area {
            padding-left: 12px;
            padding-right: 0;
        }

        &.col-reverse {
            .column-1 .content-area {
                padding-left: 12px;
                padding-right: 0;
            }
            .column-2 .content-area {
                padding-right: 12px;
                padding-left: 0;
            }
        }
    }

    .section-30-70 {
        .column-1 .content-area {
            padding-right: 12px;
            padding-left: 0;
        }
        .column-2 .content-area {
            padding-left: 12px;
            padding-right: 0;
        }

        &.col-reverse {
            .column-1 .content-area {
                padding-left: 12px;
                padding-right: 0;
            }
            .column-2 .content-area {
                padding-right: 12px;
                padding-left: 0;
            }
        }
    }

    .section-50-50 {
        .column-1 .content-area {
            padding-right: 12px;
            padding-left: 0;
        }
        .column-2 .content-area {
            padding-left: 12px;
            padding-right: 0;
        }

        &.col-reverse {
            .column-1 .content-area {
                padding-left: 12px;
                padding-right: 0;
            }
            .column-2 .content-area {
                padding-right: 12px;
                padding-left: 0;
            }
        }
    }

    .textImg .mantine-Grid-inner {
        flex-direction: row;
    }
    
    .imgText .mantine-Grid-inner {
        display: flex;
        flex-direction: row-reverse;
    }
}




// Use this field to identify the component in the CMS (Not used in the website)
